import { Box, Button, Divider, Image, Text } from "@chakra-ui/react";
import logo from "../assets/logo.png";
import menu from "../assets/menu.svg";
import close from "../assets/close.png";
import personal from "../assets/personal_banking.jpg";
import business from "../assets/business_banking.jpeg";
import about from "../assets/about_us.jpg";
import React, { useState } from "react";
import { FaUserAlt, FaBriefcase, FaRegHandshake } from "react-icons/fa";
import { AiFillBank, AiOutlineUser } from "react-icons/ai";
import { BsBoxSeam, BsBriefcase, BsNewspaper } from "react-icons/bs";
import { SlNotebook } from "react-icons/sl";
import { Link } from "react-router-dom";

const navs = [
  { title: "Personal", icon: <AiOutlineUser size={20} />, link: "/" },
  { title: "Business", icon: <BsBriefcase size={20} />, link: "/" },
  { title: "About Us", icon: <AiFillBank size={20} />, link: "/About" },
  { title: "Products", icon: <BsBoxSeam size={20} />, link: "/" },
  { title: "Services", icon: <FaRegHandshake size={20} />, link: "/" },
  { title: "Career", icon: <BsBriefcase size={20} />, link: "/" },
  { title: "Directory", icon: <SlNotebook size={20} />, link: "/" },
  {
    title: "Acquired Properties",
    icon: <AiFillBank size={20} />,
    link: "/",
  },
  {
    title: "News Advisories",
    icon: <BsNewspaper size={20} />,
    link: "/",
  },
];

const Sidebar = () => {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <Box
      backgroundColor={"white"}
      position={"relative"}
      borderEndWidth={1}
      borderColor={"#000"}
      display={"flex"}
      flexDirection={"column"}
      w={"20%"}
      h={"100%"}
    >
      <Box
        display={"flex"}
        justifyContent={"flex-start"}
        flexDir={"row"}
        gap={5}
        padding={5}
      >
        {/* {isClicked ? (
          <Button
            bgImage={menu}
            backgroundSize={"cover"}
            backgroundRepeat={"no-repeat"}
            backgroundPosition={"center"}
            w={"15%"}
            onClick={() => {
              setIsClicked(false);
            }}
          />
        ) : (
          <Button
            bgImage={close}
            backgroundSize={"cover"}
            backgroundRepeat={"no-repeat"}
            backgroundPosition={"center"}
            w={"15%"}
            onClick={() => {
              setIsClicked(true);
            }}
          />
        )} */}

        <Image src={logo} objectFit={"contain"} w={"100%"} />
      </Box>
      <Box display={"flex"} flexDir={"column"} paddingX={5}>
        <Box
          display={"flex"}
          flexDir={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          gap={3}
        >
          <Text fontFamily={"Poppins"} color={"#000E6D"}>
            Welcome
          </Text>
          <Divider orientation={"horizontal"} opacity={"0.9"} />
        </Box>
        <Text fontFamily={"Poppins"} color={"#E76D0C"} fontSize={20}>
          Enterprise Bank
        </Text>
      </Box>
      <Box display={"flex"} justifyContent={"flex-start"} padding={5}>
        <Text fontFamily={"Poppins"} color={"#000E6D"} fontSize={12}>
          Select Your Customer Type
        </Text>
      </Box>
      {isClicked ? (
        <Box
          display={"flex"}
          justifyContent={"flex-start"}
          flexDir={"column"}
          alignItems={"center"}
          marginX={10}
          gap={10}
        >
          <Link to={"/"}>
            <Box
              borderRadius={10}
              boxShadow={"0px 10px 10px #888888"}
              color={"#E76D0C"}
              _hover={{
                color: "white",
                backgroundColor: "#E76D0C",
                transitionDuration: ".5s",
              }}
            >
              <Image src={personal} borderTopRadius={10} objectFit={"cover"} />
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={5}
                paddingY={3}
                paddingX={5}
              >
                <FaUserAlt />
                <Text
                  fontFamily={"Poppins"}
                  fontSize={[10, 10, 10, 10, 12, 16]}
                >
                  Personal Banking
                </Text>
              </Box>
            </Box>
          </Link>

          <Box
            borderRadius={10}
            boxShadow={"0px 10px 10px #888888"}
            color={"#E76D0C"}
            _hover={{
              color: "white",
              backgroundColor: "#E76D0C",
              transitionDuration: ".5s",
            }}
          >
            <Image src={business} borderTopRadius={10} objectFit={"cover"} />
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={5}
              paddingY={3}
              paddingX={5}
            >
              <FaBriefcase />
              <Text fontFamily={"Poppins"} fontSize={[10, 10, 10, 10, 12, 16]}>
                Business Banking
              </Text>
            </Box>
          </Box>
          <Link to={"/About"}>
            <Box
              borderRadius={10}
              boxShadow={"0px 10px 10px #888888"}
              color={"#E76D0C"}
              _hover={{
                color: "white",
                backgroundColor: "#E76D0C",
                transitionDuration: ".5s",
              }}
            >
              <Image src={about} borderTopRadius={10} objectFit={"cover"} />
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={5}
                paddingY={3}
                paddingX={5}
              >
                <AiFillBank />
                <Text
                  fontFamily={"Poppins"}
                  fontSize={[10, 10, 10, 10, 12, 16]}
                >
                  About Us
                </Text>
              </Box>
            </Box>
          </Link>
        </Box>
      ) : (
        navs.map((item) => {
          return (
            <Link to={item.link}>
              <Button
                onClick={() => {
                  setIsClicked(true);
                }}
                color={"#E76D0C"}
                width={"100%"}
                backgroundColor={"white"}
                display={"flex"}
                justifyContent={"start"}
                mb={5}
                _hover={{
                  color: "white",
                  backgroundColor: "#E76D0C",
                  transitionDuration: ".5s",
                }}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  gap={5}
                  paddingY={3}
                  paddingX={5}
                >
                  {item.icon}
                  <Text
                    fontFamily={"Poppins"}
                    fontSize={[10, 10, 10, 10, 12, 16]}
                  >
                    {item.title}
                  </Text>
                </Box>
              </Button>
            </Link>
          );
        })
      )}
    </Box>
  );
};

export default Sidebar;
